import { Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './layout/Format';
import Home from './pages/Home';

function App() {  
  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path='/resume' element={<Home page='resume' />} />
          <Route path='/about' element={<Home page='about' />} />
          <Route path='/home' element={<Home page='about'/>} />
          <Route path='/' element={<Home page='about' />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
