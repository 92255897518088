import React from "react";
import Header from "../components/Header";

function Layout({ children }) {
  return (
    <div className="bg-light flex flex-col flex-wrap">
      <Header />
      <div className="flex flex-col flex-grow body-container">
          {children}
      </div>
    </div>
  );
}

export default Layout;