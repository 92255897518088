import React from "react";
import { Link } from "react-router-dom";
import headshot from '../img/headshot.jpg';

function Header() {
    return (
        <div id="header" className="flex flex-col header-background" style={{
            boxShadow: '0px 4px 8px rgb(4,5,47,.75)'
        }}>
            <div className="flex flex-col lg:flex-row items-center justify-center h-full header-info-container">
                <div className="flex justify-center items-center header-img">
                    <Link to='/'><img src={headshot} alt="headshot" className="rounded-full border border-lightblue border-solid headshot" /></Link>
                </div>
                <div className="flex flex-col text-left justify-center header-text">
                    <Link to='/'><h1 className="text-celestialblue font-bold text-xl sm:text-4xl pb-2">Dalyn Baldwin</h1></Link>
                    <Link to='/'><h2 className="text-light font-semibold text-md sm:text-xl">Healthcare + Innovation + Tech</h2></Link>
                </div>
            </div>
        </div>
    );
}

export default Header;