import React from "react";
import { Link } from "react-router-dom";

function Progress({ pages=['about', 'resume'], active='about'}) {
    function setUpper(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className="justify-center progress-container">
            {pages.map((page, index) => 
                {
                    return (
                        <Link 
                            to={`/${index === 0 ? '' : page}`}  
                            key={index} 
                            className={
                                `flex flex-row items-center justify-center rounded-full py-3 progress-item
                                ${page === active ? 'bg-celestialblue text-light' : 'bg-white text-celestialblue border border-celestialblue'}
                                ${index < pages.length - 1 ? 'progress-item-front' : ''}`
                            }
                        >
                            <h3 className="text-sm font-semibold">
                                {setUpper(page)}

                            </h3>
                        </Link>
                    )
                }
            )}
        </div>
    );
}

export default Progress;