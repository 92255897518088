import React from "react";
import img from '../img/footer.jpg';

function Footer() {
    return (
        <div className="flex flex-row items-center footer-background">
            <img src={img} alt="footer footer-img" style={{
                bottom: 0,
                left: 0,
                width: '20%',
                height:'auto',
                overflow: 'hidden',
                float: 'left',
                zIndex: 500,
            }} />
            <div className="flex flex-col items-center justify-center footer-content">
                <p className="text-light">&copy; {new Date().getFullYear()} Dalyn Baldwin</p>
            </div>
        </div>
    );
}

export default Footer;