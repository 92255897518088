//import React, { useEffect } from "react";
//import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Progress from '../components/Progress';
import Footer from '../components/Footer';
import Resume from '../documents/dalyn_baldwin_resume.pdf';
import { FaChevronLeft, FaChevronDown } from 'react-icons/fa';
import marriott from '../img/marriott_logo.jpg';
import eccles from '../img/eccles_logo.png';
import humana from '../img/humana_logo.png';
import lga from '../img/logan_growth_advisors_logo.jpg';
import nkf from '../img/nkf_logo.jpg';
import lds from '../img/lds_logo.jpeg';
import psmi from '../img/psmi_logo.png';
import ScrollUpButton from "react-scroll-up-button";

const Home = ({ page = 'home' }) => {
  function setUpper(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const [educationOpen, setEducationOpen] = useState(false);
  const [experienceOpen, setExperienceOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const [certsOpen, setCertsOpen] = useState(false);
  
  const aboutPage = (
    <>
      <p className='text-md md:text-lg text-grey font-normal pb-7'>
        Thanks for visiting my website!
        <br /><br />
        I am a Senior at Brigham Young University studying Information Systems and graduating in April 2024. My expertise is in data analytics and associated softwares,
        but my passion lies in healthcare. Throughout my career, I want to use the skills developed during my time at BYU to improve the healthcare industry
        and optimize data management processes and data-driven decision making.
        <br /><br />
        Beginning in August 2024, I will be attending the University of Utah to earn a Master of Healthcare Administration (MHA). During my time at Utah, I am excited to continue my educational 
        journey and merge my data analytics and technology skills with healthcare-specific business knowledge. I am confident that this combination will allow me to be an asset 
        to any healthcare organization as I help them make more informed, well-designed decisions around technology and their data utilization.
        <br /><br />
        Outside of school, I have spent the last 2 years working for Humana, a Medicare insurance company based in Louisville, KY, that also has a major presence in healthcare innovation. My roles 
        have included working as an Enterprise Information Protection (EIP) Intern and an IT Vendor Management Intern. In these roles, I have been able to work on a variety of projects that have helped
        me understand enterprise-level data management alongside overall coporate policy and controls. I have also been able to work with a variety of teams and departments, which has given me a broad
        understanding of how a large corporation operates and how data is used and managed at an executive level.
        <br /><br />
        If you are interested in learning more about my professional experience, education, or other projects I have worked on, please visit the resume page. If you would like to get in touch with me,
        I am available via email at this <a className='text-trueblue font-bold' href='mailto:dalyn.l.baldwin@gmail.com'>this</a> email.
      </p>
    </>
  );

  const resumePage = (
    <>
      <p className='text-md md:text-lg text-grey font-normal pb-7'>View my resume as a pdf <a href={Resume} target='_blank' rel='noopener noreferrer' className='link'>here</a>.</p>
      <div>
        {/* Education */}
        <div className='flex flex-col mb-7'>
          <p className={`flex flex-row text-md md:text-lg text-light font-bold resume-content bg-trueblue ${educationOpen ? 'resume-border-open' : 'resume-border-closed'}`}>Education <FaChevronLeft className={`fa fa-regular text-white font-normal ${educationOpen ? 'hidden' : ''}`} style={{fontSize: '1.5rem', marginLeft: 'auto'}} onClick={() => setEducationOpen(!educationOpen)} /><FaChevronDown className={`fa fa-regular text-white font-normal ${educationOpen ? '' : 'hidden'}`} style={{fontSize: '1.5rem', marginLeft: 'auto'}} onClick={() => setEducationOpen(!educationOpen)} /></p>
          <div className= {`flex education-content text-sm md:text-md lg:text-lg text-grey font-normal resume-content-info border border-trueblue ${educationOpen ? '' : 'hidden'}`}>
            <div className='flex flex-row items-center m-5'>
              <img src={eccles} alt='David Eccles School of Business Logo' className='w-20 h-20 rounded-full mr-3' />
              <div className='flex flex-col'>
                <p className='font-bold text-trueblue'>Master of Healthcare Administration</p>
                <p className='text-grey font-normal'><span className='text-grey font-semibold'>GPA:</span> 4.00</p>
                <p className='text-grey font-normal'><span className='text-grey font-semibold'>Associations:</span> MHA Student Association (MHASA)</p>
              </div>
            </div>
            <div className='flex flex-row items-center mb-5 ml-5 mr-5'>
              <img src={marriott} alt='BYU Marriott School of Business Logo' className='w-20 h-20 rounded-full mr-3' />
              <div className='flex flex-col'>
                <p className='font-bold text-trueblue'>Bachelor of Science, Information Systems</p>
                <p className='text-grey font-normal'><span className='text-grey font-semibold'>GPA:</span> 3.70</p>
                <p className='text-grey font-normal'><span className='text-grey font-semibold'>Associations:</span> Healthcare Leadership Association (HLA), Association for Information Systems (AIS)</p>
              </div>
            </div>
          </div>
        </div>

        {/* Experience */}
        <div className='flex flex-col mb-7'>
          <p className={`flex flex-row text-md md:text-lg text-light font-bold resume-content bg-trueblue ${experienceOpen ? 'resume-border-open' : 'resume-border-closed'}`}>Experience <FaChevronLeft className={`fa fa-regular text-white font-normal ${experienceOpen ? 'hidden' : ''}`} style={{fontSize: '1.5rem', marginLeft: 'auto'}} onClick={() => setExperienceOpen(!experienceOpen)} /><FaChevronDown className={`fa fa-regular text-white font-normal ${experienceOpen ? '' : 'hidden'}`} style={{fontSize: '1.5rem', marginLeft: 'auto'}} onClick={() => setExperienceOpen(!experienceOpen)} /></p>
          <div className= {`flex education-content text-sm md:text-md lg:text-lg text-grey font-normal resume-content-info border border-trueblue my-auto ${experienceOpen ? '' : 'hidden'}`}>
            <div className='flex flex-row items-center m-5'>
              <img src={humana} alt='Humana, Inc Logo' className='w-20 h-20 rounded-full mr-3' />
              <div className='flex flex-col'>
                <p className='font-bold text-trueblue'>Enterprise Information Protection Intern</p>
                <div className='flex flex-row mb-2'>
                  <p className='text-grey font-normal'>Wrote and implemented cybersecurity policies and associated processes.</p>
                </div>
                <p className='font-bold text-trueblue'>IT Vendor Management Intern</p>
                <div className='flex flex-row'>
                  <p className='text-grey font-normal'>Performed data analytics on contract renewal data to highlight urgent tasks and upcoming renewal information.</p>
                </div>
              </div>
            </div>
            <div className='flex flex-row items-center  mb-5 ml-5 mr-5'>
              <img src={lga} alt='Logan Growth Advisors Logo' className='w-20 h-20 rounded-full mr-3 border border-dark' />
              <div className='flex flex-col'>
                <p className='font-bold text-trueblue'>Data Analyst</p>
                <div className='flex flex-row mb-2'>
                  <p className='text-grey font-normal'>Managed 5,000+ proprietary lead entries, including data cleaning and verification, which were converted into Salesforce entries.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Projects */}
        <div className='flex flex-col mb-7'>
          <p className={`flex flex-row text-md md:text-lg text-light font-bold resume-content bg-trueblue ${projectsOpen ? 'resume-border-open' : 'resume-border-closed'}`}>Other Projects and Experiences <FaChevronLeft className={`fa fa-regular text-white font-normal ${projectsOpen ? 'hidden' : ''}`} style={{fontSize: '1.5rem', marginLeft: 'auto'}} onClick={() => setProjectsOpen(!projectsOpen)} /><FaChevronDown className={`fa fa-regular text-white font-normal ${projectsOpen ? '' : 'hidden'}`} style={{fontSize: '1.5rem', marginLeft: 'auto'}} onClick={() => setProjectsOpen(!projectsOpen)} /></p>
          <div className= {`flex education-content text-sm md:text-md lg:text-lg text-grey font-normal resume-content-info border border-trueblue my-auto ${projectsOpen ? '' : 'hidden'}`}>
            <div className='flex flex-row items-center m-5'>
              <img src={nkf} alt='National Kidney Foundation Logo' className='w-20 h-20 rounded-full mr-3' />
              <div className='flex flex-col'>
                <p className='font-bold text-trueblue'>Kidney-specific Nutrition Tracker</p>
                <div className='flex flex-row mb-2'>
                  <p className='text-grey font-normal'>Designed a SQL database and Django web-application, which included embedded data-based daily progress visualizations and customized recommendations for the nutrients needed by ESRD patients. Thorough documentation was also written.</p>
                </div>
              </div>
            </div>
            <div className='flex flex-row items-center  mb-5 ml-5 mr-5'>
              <img src={lds} alt='Church of Jesus Christ of Latter-day Saints Logo' className='w-20 h-20 rounded-full mr-3 border border-dark' />
              <div className='flex flex-col'>
                <p className='font-bold text-trueblue'>Spanish Speaking Representative</p>
                <div className='flex flex-row mb-2'>
                  <p className='text-grey font-normal'>Taught english to 8-12 native spanish speakers while serving the community through service projects and other activities.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Certs */}
        <div className='flex flex-col mb-7'>
          <p className={`flex flex-row text-md md:text-lg text-light font-bold resume-content bg-trueblue ${certsOpen ? 'resume-border-open' : 'resume-border-closed'}`}>Certifications and Achievements <FaChevronLeft className={`fa fa-regular text-white font-normal ${certsOpen ? 'hidden' : ''}`} style={{fontSize: '1.5rem', marginLeft: 'auto'}} onClick={() => setCertsOpen(!projectsOpen)} /><FaChevronDown className={`fa fa-regular text-white font-normal ${certsOpen ? '' : 'hidden'}`} style={{fontSize: '1.5rem', marginLeft: 'auto'}} onClick={() => setCertsOpen(!certsOpen)} /></p>
          <div className= {`flex education-content text-sm md:text-md lg:text-lg text-grey font-normal resume-content-info border border-trueblue my-auto ${certsOpen ? '' : 'hidden'}`}>
            <div className='flex flex-row items-center m-5'>
              <img src={psmi} alt='National Kidney Foundation Logo' className='w-20 h-20 rounded-full mr-3' />
              <p className='font-bold text-trueblue'>Professional Scrum Master I</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    document.title = `Dalyn Baldwin | ${setUpper(page)}`;
  }, [page]);

  return (
    <div className='flex flex-col flex-grow' style={{marginTop: '7px', zIndex:'9999'}}>
      <div className='flex flex-row flex-grow content-container'>
        <Progress pages={['about', 'resume']} active={page} />
        <div className='flex flex-col flex-grow content-info'>
          <h1 className='text-3xl text-center font-bold text-trueblue pb-7'>{setUpper(page)}</h1>
          {page === 'about' && aboutPage}
          {page === 'resume' && resumePage}
        </div>
      </div>
      <ScrollUpButton 
        EasingType='easeInOutQuad'
        ShowAtPosition={0}
        AnimationDuration={0}
        ContainerClassName='scroll-up-container'
      />
      <Footer />
    </div>
  );
};

export default Home;